export const path = {
  login: '/login',
  signup: '/signup',
  profile: '/profile',
  resetPassword: '/reset-password',
  confirmEmail: '/confirm-email',
  dashboard: '/dashboard',
  home: '/home',
  stepone: '/stepone',
  steptwo: '/steptwo',
}
