export const base = "https://zenonbooking-master.herokuapp.com/"
export const login = "/users/login"
export const me = "/users/me"
export const signup = "/users"

export const store = "/store"

export const getMyAppointments = "/users/appointments/0"
export const appointment = "/appointment"

export const service = "/service"
export const product = "/product"
export const order = "/order"

export const workday = "/workday/"
export const getWDAvailableForBooking = "/workday/availableForBooking"
export const createWorkDay = "/workday"
export const getAllWorkDayWithDay = "/workday/"
export const deleteSchedule = "/workday/schedule"

export const barber = "/barber"

export const doPayment = "/doPayment"
export const stripeCard = "/stripe/card"
export const stripeSubscriptions = "/stripe/subscriptions"
export const stripeBuyNotifications = "/stripe/notifications"

export const dashboard = "/dashboard"

export const notifications = "/notifications"