import { createSlice } from '@reduxjs/toolkit'
import { auth, firebase } from 'utils/firebase'

import axios from 'axios'

const md5 = require('js-md5');
const routes = require('../utils/routes');

// ------------------------------------
// State
// ------------------------------------

const initialState = {
  checked: false,
  loggedIn: false,
  me: {},
  user: {},
  storeInfo: {}
}
// ------------------------------------
// Slices
// -----------------------------------

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setMe: (state, action) => ({
      ...state,
      me: action.payload.me,
      loggedIn: action.payload.loggedIn,
      checked: true,
    }),
    setLoggedIn: (state, action) => ({
      ...state,
      loggedIn: action.payload,
      user: action.payload.user
    }),
    setAppointments: (state, action) => ({
      ...state,
      appointments: action.payload,
    }),
    setServices: (state, action) => ({
      ...state,
      services: action.payload,
    }),
    setStoreInfo: (state, action) => ({
      ...state,
      storeInfo: action.payload,
    }),
  },
})

// ------------------------------------
// Actions
// -----------------------------------

export const authenticate = (user_server) => (dispatch) => {
  auth.onAuthStateChanged(async (me) => {
    if (!me) {
      return dispatch(
        slice.actions.setMe({
          loggedIn: false,
          checked: true,
          me: {}
        }),
      )
    }

    // login
    return dispatch(
      slice.actions.setMe({
        me: {
          email: me.email,
          fbToken: me.refreshToken,
          displayName: me.displayName
        },
        checked: true,
      }),
    )
  })

  if (user_server) {
    routes.getStoreInfo().then((result) => {
      dispatch(slice.actions.setStoreInfo(result))
    })
    return dispatch(
      slice.actions.setLoggedIn({
        loggedIn: true,
        user: user_server
      }),
    )
  }
  if (!user_server
    && window.localStorage.getItem("user_email")
    && window.localStorage.getItem("user_token")
    && window.localStorage.getItem("user_storeid")) {
    let user = {
      email: window.localStorage.getItem("user_email"),
      token: window.localStorage.getItem("user_token"),
      store_id: window.localStorage.getItem("user_storeid")
    }

    axios.defaults.headers.common['x-auth'] = user.token;
    axios.defaults.headers.common['storeid'] = user.store_id;

    routes.getStoreInfo().then((result) => {
      dispatch(slice.actions.setStoreInfo(result))
    })

    return dispatch(
      slice.actions.setLoggedIn({
        loggedIn: true,
        user: user
      }))
  }

}

const signup = ({ fullName, email, password }) => () =>
  new Promise(async (resolve, reject) => {
    try {
      // create user
      const { user } = await auth.createUserWithEmailAndPassword(
        email,
        md5(password),
      )

      // send confirmation email
      await user.sendEmailVerification()

      resolve(user)
    } catch (err) {
      reject(err)
    }
  })

const login = ({ email, password, codice_salone }) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const { user } = await auth.signInWithEmailAndPassword(email, md5(password))
      if (!user) reject(new Error('Failed to login. please try it again later'))
      if (!user.emailVerified) await user.sendEmailVerification()

      const user_server = await routes.login(email, md5(password), user.getIdToken(), codice_salone, null)

      dispatch(authenticate(user_server))

      const store_info = await routes.getStoreInfo()
      dispatch(slice.actions.setStoreInfo(store_info))

      resolve(user_server)
    } catch (err) {
      reject(err)
    }
  })

const loginProvider = ({ email, password, codice_salone }, provider) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const resp = await auth.signInWithPopup(provider)

      if (!resp.user) reject(new Error('Failed to login. please try it again later'))

      const accessToken = resp.credential.accessToken;
      const idToken = await resp.user.getIdToken(true);

      !resp.user.email && await resp.user.updateEmail("generated_" + resp.additionalUserInfo.profile.id + "@zenonbooking.it")

      const user_server = await routes.login(resp.user.email, null, accessToken, codice_salone, idToken)

      dispatch(authenticate(user_server))

      const store_info = await routes.getStoreInfo()
      dispatch(slice.actions.setStoreInfo(store_info))

      resolve(user_server)
    } catch (err) {
      reject(err)
    }
  })

const logout = () => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      await auth.signOut()
      window.localStorage.removeItem("user_storeid")
      window.localStorage.removeItem("user_email")
      window.localStorage.removeItem("user_token")
      window.localStorage.removeItem("user_firstName")
      window.localStorage.removeItem("user_lastName")

      dispatch(
        slice.actions.setMe({
          checked: true,
          loggedIn: false,
          me: {}
        }),
      )
      dispatch(
        slice.actions.setLoggedIn({
          loggedIn: false,
          user: {}
        }),
      )
      resolve()
    } catch (err) {
      reject(err)
    }
  })

const resetPassword = (email) => () => auth.sendPasswordResetEmail(email)

const refreshAppointmentList = async (dispatch) => {
  new Promise(async (resolve, reject) => {
    try {
      let list = await routes.getMyAppointments()
      dispatch(slice.actions.setAppointments(list))
      resolve(list)
    } catch (err) {
      reject(err)
    }
  })
}

const refreshServicesList = async (dispatch) => {
  new Promise(async (resolve, reject) => {
    try {
      let list = await routes.getServices()
      dispatch(slice.actions.setServices(list))
      resolve(list)
    } catch (err) {
      reject(err)
    }
  })
}

// ------------------------------------
// Exports
// ------------------------------------

export const actions = {
  ...slice.actions,
  authenticate,
  signup,
  login,
  loginProvider,
  logout,
  resetPassword,
  refreshAppointmentList,
  refreshServicesList
}

export default slice.reducer
