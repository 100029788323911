import { useEffect } from 'react'
import { Provider } from 'react-redux'
import { loadFontIcons } from 'components/FontIcon'
import store from 'utils/store'
import axios from 'axios'
import axiosRetry from 'axios-retry'
import Router from './routes'

axios.defaults.baseURL = require("./constants/endpoints").base;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['x-auth'] = '';

axiosRetry(axios, {
  retries: 3, // number of retries
  retryDelay: (retryCount) => {
    return retryCount * 2000; // time interval between retries
  },
  retryCondition: (error) => {
    if (error.response.status >= 400 && error.response.status < 500)
      return false
    else
      return true
  }
});

function App() {
  useEffect(() => {
    loadFontIcons()
  }, [])

  return (
    <Provider store={store}>
      <div data-testid="app" className="app">
        <Router />
      </div>
    </Provider>
  )
}

export default App
