import axios from 'axios'
import moment from 'moment';

const endpoints = require('../constants/endpoints')

const decorateAppointment = (app) => {
    const appToRet = app

    if (!app)
        return

    appToRet.day = moment(appToRet.day).format("dddd D MMMM YYYY")

    const hours = ("0" + Math.floor(app.startTime / 60)).slice(-2);
    const minutes = ("0" + app.startTime % 60).slice(-2);
    app.startTime = hours + ":" + minutes;

    return app
}

let errorHandling = (e, reject) => {
    if (!e)
        reject({ message: "OPS! Qualcosa è andato storto :(" })
    else if (e.request.status === 0)
        reject({ code: "Errore di connessione", message: "Non è stato possibile contattare il server. Sei collegato a internet?" })
    else if (!e.request || !e.response)
        reject({ message: "OPS! Qualcosa è andato storto :(" })
    else
        reject({ message: e.response.data.ErrMessage });
}

export const getMyAppointments = () => {
    return new Promise(async (resolve, reject) => {
        try {
            let list;
            const res = await axios.get(endpoints.getMyAppointments);
            if (res.status === 200) {
                list = res.data;
            }
            for (let i = 0; i < list.length; i++) {
                list[i] = decorateAppointment(list[i]);
            }
            resolve(list);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}

export const login = (email, password, fbToken, store_code, idToken) => {
    return new Promise(async (resolve, reject) => {
        try {
            let user
            const res = await axios.post(endpoints.login, {
                email: email,
                password: password,
                store_code: store_code,
                facebookToken: null,
                appleAccess: null,
                newToken: idToken
            }, {
                headers: {
                    firebaseToken: fbToken
                }
            });
            if (res.status === 200) {
                user = res.data;
                user.token = res.headers["x-auth"]
                axios.defaults.headers.common['x-auth'] = user.token;
                axios.defaults.headers.common['storeid'] = res.headers["storeid"];

                window.localStorage.setItem("user_storeid", res.headers["storeid"])
                window.localStorage.setItem("user_email", user.email)
                window.localStorage.setItem("user_token", user.token)
                window.localStorage.setItem("user_firstName", user.firstName)
                window.localStorage.setItem("user_lastName", user.lastName)
            }
            resolve(user);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}
export const me = () => {
    return new Promise(async (resolve, reject) => {
        try {
            let toReturn
            const res = await axios.get(endpoints.me)
            if (res.status === 200) {
                toReturn = res.data;
            }
            resolve(toReturn);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}
export const signup = (email, password, firstName, lastName, phone, facebookID, appleID) => {
    return new Promise(async (resolve, reject) => {
        try {
            let user
            const res = await axios.post(endpoints.signup, {
                email: email,
                password: password,
                firstName: firstName,
                lastName: lastName,
                phone: phone,
                facebookID,
                appleID
            }, { "headers": { 'Content-Type': 'application/json', } });
            if (res.status === 200) {
                user = res.data;
            }
            resolve(user);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}

export const signupStore = (store) => {
    return new Promise(async (resolve, reject) => {
        try {
            let toReturn
            const res = await axios.post(endpoints.store,
                store,
                { "headers": { 'Content-Type': 'application/json', } });
            if (res.status === 200) {
                toReturn = res.data;
            }
            resolve(toReturn);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}

export const getStoreInfo = () => {
    return new Promise(async (resolve, reject) => {
        try {
            let toReturn
            const res = await axios.get(endpoints.store)
            window.localStorage.setItem("codice_salone", res.data.store.code)
            if (res.status === 200) {
                toReturn = res.data;
            }
            resolve(toReturn);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}

export const getServices = () => {
    return new Promise(async (resolve, reject) => {
        try {
            let list;
            const res = await axios.get(endpoints.service);
            if (res.status === 200) {
                list = res.data;
            }
            resolve(list);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}

export const saveService = (service) => {
    return new Promise(async (resolve, reject) => {
        try {
            let result;
            const res = await axios.post(endpoints.service, service);
            if (res.status === 200) {
                result = res.data;
            }
            resolve(result);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}

export const deleteService = (serviceID) => {
    return new Promise(async (resolve, reject) => {
        try {
            let result;
            const res = await axios.delete(endpoints.service + "/" + serviceID);
            if (res.status === 200) {
                result = res.data;
            }
            resolve(result);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}

export const getWorkdaysAvailableForBooking = (day, services) => {
    return new Promise(async (resolve, reject) => {
        try {
            let list;
            const res = await axios.post(endpoints.getWDAvailableForBooking, {
                day,
                services
            });
            if (res.status === 200) {
                list = res.data;
            }
            resolve(list);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}

export const createAppointment = (day, time, services, barber, description) => {
    return new Promise(async (resolve, reject) => {
        try {
            let result;
            const res = await axios.post(endpoints.appointment, {
                day,
                time,
                services,
                barber,
                description
            });
            if (res.status === 200) {
                result = res.data;
            }
            resolve(result);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}

export const deleteAppointment = (appID) => {
    return new Promise(async (resolve, reject) => {
        try {
            let result;
            const res = await axios.delete(endpoints.appointment + "/" + appID);
            if (res.status === 200) {
                result = res.data;
            }
            resolve(result);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}

export const completeAppointment = (appID) => {
    return new Promise(async (resolve, reject) => {
        try {
            let result;
            const res = await axios.put(endpoints.appointment + "/" + appID);
            if (res.status === 200) {
                result = res.data;
            }
            resolve(result);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}

export const getAppointmentsWithDay = (day) => {
    return new Promise(async (resolve, reject) => {
        try {
            let result;
            const res = await axios.get(endpoints.appointment + "/" + day);
            if (res.status === 200) {
                result = res.data;
            }
            resolve(result);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}

export const createWorkDay = (workDay) => {
    return new Promise(async (resolve, reject) => {
        try {
            let result;
            const res = await axios.post(endpoints.createWorkDay, workDay);
            if (res.status === 200) {
                result = res.data;
            }
            resolve(result);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}

export const deleteSchedule = (schedule) => {
    return new Promise(async (resolve, reject) => {
        try {
            let result;
            const res = await axios.put(endpoints.deleteSchedule, schedule);
            if (res.status === 200) {
                result = res.data;
            }
            resolve(result);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}

export const getAllWorkDayWithDay = (day) => {
    return new Promise(async (resolve, reject) => {
        try {
            let list;
            const res = await axios.get(endpoints.getAllWorkDayWithDay + day);
            if (res.status === 200) {
                list = res.data;
            }
            resolve(list);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}

export const getAllWorkDayByMonthAndBarber = (month, barber) => {
    return new Promise(async (resolve, reject) => {
        try {
            let list;
            const res = await axios.get(endpoints.workday + barber + "/month/" + month);
            if (res.status === 200) {
                list = res.data;
            }
            resolve(list);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}

export const deleteAllWorkDayByMonthAndBarber = (month, barber) => {
    return new Promise(async (resolve, reject) => {
        try {
            let list;
            const res = await axios.delete(endpoints.workday + barber + "/month/" + month);
            if (res.status === 200) {
                list = res.data;
            }
            resolve(list);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}

export const getBarbers = (deleted) => {
    if (!deleted)
        deleted = false
    return new Promise(async (resolve, reject) => {
        try {
            let list;
            const res = await axios.get(endpoints.barber + "/" + deleted);
            if (res.status === 200) {
                list = res.data;
            }
            resolve(list);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}

export const saveBarber = (barber) => {
    return new Promise(async (resolve, reject) => {
        try {
            let list;
            const res = await axios.post(endpoints.barber, barber);
            if (res.status === 200) {
                list = res.data;
            }
            resolve(list);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}

export const deleteBarber = (barberID) => {
    return new Promise(async (resolve, reject) => {
        try {
            let list;
            const res = await axios.delete(endpoints.barber + "/" + barberID);
            if (res.status === 200) {
                list = res.data;
            }
            resolve(list);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}

export const doPayment = (tokenId, description, type) => {
    return new Promise(async (resolve, reject) => {
        try {
            let result;
            const res = await axios.post(endpoints.doPayment, { tokenId, description, type });
            if (res.status === 200) {
                result = res.data;
            }
            resolve(result);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}

export const addCard = (cardToken, customerID) => {
    return new Promise(async (resolve, reject) => {
        try {
            let result;
            const res = await axios.post(endpoints.stripeCard, { cardToken, customerID });
            if (res.status === 200) {
                result = res.data;
            }
            resolve(result);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}

export const removeCard = (customerID, cardID) => {
    return new Promise(async (resolve, reject) => {
        try {
            let result;
            const res = await axios.request({
                method: 'delete',
                url: endpoints.stripeCard,
                data: JSON.stringify({
                    customerID,
                    cardID
                })
            });
            if (res.status === 200) {
                result = res.data;
            }
            resolve(result);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}

export const updateSubscription = (customerID, type) => {
    return new Promise(async (resolve, reject) => {
        try {
            let result;
            const res = await axios.put(endpoints.stripeSubscriptions, { customerID, type });
            if (res.status === 200) {
                result = res.data;
            }
            resolve(result);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}

export const buyNotifications = (customerID) => {
    return new Promise(async (resolve, reject) => {
        try {
            let result;
            const res = await axios.post(endpoints.stripeBuyNotifications, { customerID });
            if (res.status === 200) {
                result = res.data;
            }
            resolve(result);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}

export const getDashboard = () => {
    return new Promise(async (resolve, reject) => {
        try {
            let list;
            const res = await axios.get(endpoints.dashboard);
            if (res.status === 200) {
                list = res.data;
            }
            resolve(list);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}
export const updateInstagramName = (instaNick) => {
    return new Promise(async (resolve, reject) => {
        try {
            let list;
            const res = await axios.put(endpoints.store + "/instagram", { instagram: instaNick });
            if (res.status === 200) {
                list = res.data;
            }
            resolve(list);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}
export const updateStoreStatus = (status) => {
    return new Promise(async (resolve, reject) => {
        try {
            let list;
            const res = await axios.put(endpoints.store + "/storeStatus", { status });
            if (res.status === 200) {
                list = res.data;
            }
            resolve(list);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}
export const updateCreditsStatus = (status) => {
    return new Promise(async (resolve, reject) => {
        try {
            let list;
            const res = await axios.put(endpoints.store + "/creditsStatus", { status });
            if (res.status === 200) {
                list = res.data;
            }
            resolve(list);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}

export const broadcastNotification = (title, message) => {
    return new Promise(async (resolve, reject) => {
        try {
            let result;
            const res = await axios.post(endpoints.notifications + "/store", { title, message });
            if (res.status === 200) {
                result = res.data;
            }
            resolve(result);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}
export const saveProduct = (product) => {
    return new Promise(async (resolve, reject) => {
        try {
            let list;
            const res = await axios.post(endpoints.product, product);
            if (res.status === 200) {
                list = res.data;
            }
            resolve(list);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}
export const getProducts = () => {
    return new Promise(async (resolve, reject) => {
        try {
            let list;
            const res = await axios.get(endpoints.product);
            if (res.status === 200) {
                list = res.data;
            }
            resolve(list);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}
export const deleteProduct = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let list;
            const res = await axios.delete(endpoints.product + "/" + id);
            if (res.status === 200) {
                list = res.data;
            }
            resolve(list);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}
export const saveOrder = (order) => {
    return new Promise(async (resolve, reject) => {
        try {
            let list;
            const res = await axios.post(endpoints.order, order);
            if (res.status === 200) {
                list = res.data;
            }
            resolve(list);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}
export const getOrders = () => {
    return new Promise(async (resolve, reject) => {
        try {
            let list;
            const res = await axios.get(endpoints.order);
            if (res.status === 200) {
                list = res.data;
            }
            resolve(list);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}
export const deleteOrder = (orderID) => {
    return new Promise(async (resolve, reject) => {
        try {
            let result;
            const res = await axios.delete(endpoints.order + "/" + orderID);
            if (res.status === 200) {
                result = res.data;
            }
            resolve(result);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}
export const updateStatusOrder = (orderID, status) => {
    return new Promise(async (resolve, reject) => {
        try {
            let result;
            const res = await axios.put(endpoints.order + "/status", { id: orderID, status });
            if (res.status === 200) {
                result = res.data;
            }
            resolve(result);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}

export const enableIscri = () => {
    return new Promise(async (resolve, reject) => {
        try {
            let result;
            const res = await axios.get(endpoints.signup + "/iscri");
            if (res.status === 200) {
                result = res.data;
            }
            resolve(result);
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}

export const deleteAccount = () => {
    return new Promise(async (resolve, reject) => {
        try {
            let result;
            const res = await axios.delete(endpoints.signup);
            if (res.status === 200) {
                result = res.data;
            }
            resolve("Deleted");
        } catch (e) {
            errorHandling(e, reject)
        }
    });
}