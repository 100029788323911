import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/performance'
import 'firebase/analytics'
import config from './config'

firebase.initializeApp(config.firebase)
firebase.analytics()

firebase.languageCode = "it"

const _auth = firebase.auth()
_auth.languageCode = "it"

const _facebookProvider = new firebase.auth.FacebookAuthProvider();
_facebookProvider.addScope("email")
_facebookProvider.addScope("public_profile")

export const auth = _auth
export const storage = firebase.storage().ref()
export const performance = firebase.performance()
export const firestore = firebase.firestore()
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const facebookProvider = _facebookProvider
export const appleProvider = new firebase.auth.OAuthProvider('apple.com');

export default firebase
